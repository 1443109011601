import axios from "axios";
import {
  API_BASE_URL,
  paymentInitialTicketPayload,
  paymentReceiptPayload,
  saveCardInitialTicketPayload,
  saveCardReceiptPayload,
} from "../../config/constants";
import { calculateTotalForInvoices, storeLog, validateForm } from "../../utils";

/**
 * Sends a request to generate an initial ticket for saving a card in the Moneris system.
 *
 * - Combines the provided credentials and payload data to make a request for the initial ticket.
 * - If a valid ticket is received, it calls `saveCardHandleInitialTicket` to process the next steps in saving the card.
 *
 * @param {string} accName - The account name for the customer.
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {Object} credentials - The credentials needed for authentication.
 * @param {Object} formData - Form data containing payment details.
 * @param {Object} paynow - Payment options related to the current transaction.
 * @param {string} currencyCode - The currency code for the transaction (e.g., "USD").
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Object} monerisSaveCardOptions - Additional options for saving the card using Moneris.
 *
 * @returns {Promise<void>} A promise that resolves when the initial ticket request is complete.
 * @throws {Error} Logs the error if the initial ticket request fails.
 */
const getInitialTicketForSaveCard = async (
  accName,
  isMonerisTesting,
  credentials,
  formData,
  paynow,
  currencyCode,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  const { data } = await axios.post(`${API_BASE_URL}/moneris/manage-tickets`, {
    ...saveCardInitialTicketPayload,
    ...credentials,
    cust_id: accName.replace(/[@#$%^&*()!~']/g, " "),
    guid: guid,
    corp_id: corpid,
    action_type: "Save Card InitialTicket",
    IsTesting: isMonerisTesting,
  });

  if (data?.response?.success !== "true") {
    throw new Error("Error saving card");
  }

  if (data?.response?.ticket) {
    await saveCardHandleInitialTicket(
      accName,
      isMonerisTesting,
      data?.response?.ticket,
      credentials,
      formData,
      paynow,
      currencyCode,
      setLoading,
      endpoint,
      guid,
      corpid,
      token,
      sendrespons,
      monerisSaveCardOptions
    );
  }
};

/**
 * Handles the processing of the initial ticket for saving a card in the Moneris system.
 *
 * - Builds the payload using the provided ticket, form data, and additional options for saving the card.
 * - Sends a request to process the transaction with the provided card details.
 * - If successful, it calls `saveCardHandleFinalTicket` to finalize the card save process.
 *
 * @param {string} accName - The account name for the customer.
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} ticket - The initial ticket received from the Moneris system.
 * @param {Object} credentials - The credentials needed for authentication.
 * @param {Object} formData - Form data containing payment details such as cardholder name, card number, expiry date, and CVV.
 * @param {Object} paynow - Payment options related to the current transaction.
 * @param {string} currencyCode - The currency code for the transaction (e.g., "USD").
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Object} monerisSaveCardOptions - Additional options for saving the card using Moneris.
 *
 * @returns {Promise<void>} A promise that resolves when the initial ticket processing is complete.
 * @throws {Error} Logs the error if the card processing request fails.
 */
const saveCardHandleInitialTicket = async (
  accName,
  isMonerisTesting,
  ticket,
  credentials,
  formData,
  paynow,
  currencyCode,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  try {
    let payload = {
      ticket: ticket,
      action: "process_transaction",
      cardholder: formData.cardHolderName,
      pan: formData.cardNumber.replace(/-/g, ""),
      expiry_date: formData.expiry.replace(/\//g, ""),
      cvv: formData.cvv,
      email: paynow?.Email,
      currency_code: currencyCode,
      card_data_key: "new",
      pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
      guid: guid,
      corp_id: corpid,
      action_type: "Save Card Process transaction",
      IsTesting: isMonerisTesting,
    };

    if (monerisSaveCardOptions?.FirstNameSet === "true") {
      payload.first_name = formData.first_name;
    }

    if (monerisSaveCardOptions?.LastNameSet === "true") {
      payload.last_name = formData.last_name;
    }

    if (monerisSaveCardOptions?.PhoneNumSet === "true") {
      payload.phone = formData.phone;
    }
    const { data } = await axios.post(
      `${API_BASE_URL}/moneris/process-transactions`,
      payload
    );

    setLoading(false);

    if (
      data &&
      data.response &&
      data.response.success === "true" &&
      data.response.ticket
    ) {
      await saveCardHandleFinalTicket(
        accName,
        isMonerisTesting,
        data.response.ticket,
        credentials,
        formData,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        monerisSaveCardOptions
      );
    } else {
      console.error("Response data is invalid or incomplete:", data);
    }
  } catch (error) {
    setLoading(false);
    console.error("Error:", error);
  }
};

/**
 * Handles the final step of saving a card in the Moneris system by processing the final ticket.
 *
 * - Sends a request with the final ticket to save the card and retrieve the card token and details.
 * - After receiving the response, it constructs the Moneris credit card object and calls `saveCardPutRequest` to store the card details.
 *
 * @param {string} accName - The account name for the customer.
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} ticket - The final ticket received from the Moneris system for processing the card.
 * @param {Object} credentials - The credentials needed for authentication.
 * @param {Object} formData - Form data containing payment details.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Object} monerisSaveCardOptions - Additional options for saving the card using Moneris.
 *
 * @returns {Promise<void>} A promise that resolves when the final ticket processing and card saving is complete.
 * @throws {Error} Logs the error if the card final processing request fails.
 */
const saveCardHandleFinalTicket = async (
  accName,
  isMonerisTesting,
  ticket,
  credentials,
  formData,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/moneris/manage-tickets`,
      {
        ...saveCardReceiptPayload,
        ...credentials,
        ticket,
        guid: guid,
        corp_id: corpid,
        action_type: "Save Card Final Receipt",
        IsTesting: isMonerisTesting,
      }
    );

    const theMonerisCC = {
      CcToken: data?.response?.receipt?.cc?.tokenize?.datakey,
      CcType: data?.response?.receipt?.cc?.card_type,
      First4Num: data?.response?.receipt?.cc?.tokenize?.first4last4.substring(
        0,
        4
      ),
      Last4Num: data?.response?.receipt?.cc?.tokenize?.first4last4.slice(-4),
      ExpYear: data?.response?.receipt?.cc?.expiry_date?.substring(2),
      ExpMonth: data?.response?.receipt?.cc?.expiry_date?.substring(0, 2),
      StoreID: credentials.store_id,
    };

    await saveCardPutRequest(
      accName,
      isMonerisTesting,
      theMonerisCC,
      formData,
      endpoint,
      guid,
      corpid,
      token,
      sendrespons,
      monerisSaveCardOptions
    );
  } catch (err) {
    console.warn(err);
  }
};

/**
 * Sends a PUT request to save the card details to SDS.
 *
 * - Updates the response payload with the card details and optional billing address information.
 * - Sends the updated payload to the backend to save the card and logs the success or failure of the operation.
 *
 * @param {string} accName - The account name for the customer.
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {Object} theMonerisCC - Moneris credit card details object containing token, card type, and other information.
 * @param {Object} formData - Form data containing billing and card details.
 * @param {string} endpoint - API endpoint URL for the PUT request.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the PUT request.
 * @param {Object} monerisSaveCardOptions - Additional options for saving the card, such as whether to include billing address details.
 *
 * @returns {Promise<void>} A promise that resolves when the card details are successfully saved.
 * @throws {Error} Logs the error if the PUT request fails.
 */
const saveCardPutRequest = async (
  accName,
  isMonerisTesting,
  theMonerisCC,
  formData,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  let payload = sendrespons;
  try {
    payload.IsCcSaved = true;
    payload.TheMonerisCC = theMonerisCC;
    payload.guid = guid;
    payload.corp_id = corpid;
    payload.IsTesting = isMonerisTesting;

    if (monerisSaveCardOptions?.BillingAddressSet === "true") {
      payload.PayerAccount = {
        ...payload.PayerAccount,
        Address: {
          Line1: formData.addressLine1,
          Line2: formData.addressLine2,
          City: formData.city,
          Country: formData.country,
          PostalCode: formData.postalCode,
          Province: formData.province,
        },
      };
    }

    const { data, status } = await axios.put(
      `${endpoint}/Put?id=${guid}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Origin_Custom: endpoint,
        },
      }
    );

    storeLog(
      "info",
      `Save Card PUT request: "${JSON.stringify(payload)}"`,
      status,
      guid,
      corpid,
      "PUT Call - Save Card",
      `PUT Call - Save Card for moneris for ${accName}`,
      "Sucess"
    );
  } catch (err) {
    storeLog(
      "error",
      `Save Card PUT request: "${JSON.stringify(payload)}"`,
      500,
      guid,
      corpid,
      "PUT Call - Save Card",
      `PUT Call -Save Card for moneris for ${accName}`,
      "Failure"
    );
    console.warn(err);
  }
};

/**
 * Handles the process of saving a card for multiple grouped invoices.
 *
 * - Iterates through each grouped invoice item and calls `getInitialTicketForSaveCard` to start the process of saving the card for each group.
 * - Sets the loading state during the process and controls the display of the card save modal.
 * - When the process is complete for all grouped invoices, it stops the loading state and shows the card save confirmation.
 *
 * @param {string} accName - The account name for the customer.
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {Object} formData - Form data containing payment details such as cardholder name, card number, expiry date, and CVV.
 * @param {Object} paynow - Payment options related to the current transaction.
 * @param {string} currencyCode - The currency code for the transaction (e.g., "USD").
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Array<Object>} groupedInvoices - List of grouped invoice items containing Moneris API credentials.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Object} monerisSaveCardOptions - Additional options for saving the card, such as whether to include billing address details.
 * @param {Function} setShowCardSaveModal - Function to control the display of the "Save Card" modal.
 * @param {Function} setIsCardSaveOpen - Function to control the state of card save confirmation.
 * @param {Function} setIsCardSaveFailedOpen - Function to control the state of card save failure.
 *
 * @returns {Promise<void>} A promise that resolves when the card saving process for all grouped invoices is complete.
 * @throws {Error} Logs the error if any part of the card saving process fails.
 */
export const handleSaveCard = async (
  accName,
  isMonerisTesting,
  formData,
  paynow,
  currencyCode,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  groupedInvoices,
  sendrespons,
  monerisSaveCardOptions,
  setShowCardSaveModal,
  setIsCardSaveOpen,
  setIsCardSaveFailedOpen
) => {
  try {
    setLoading(true);
    setShowCardSaveModal(false);
    for (let index = 0; index < groupedInvoices.length; index++) {
      const groupedInvoiceItem = groupedInvoices[index];
      await getInitialTicketForSaveCard(
        accName,
        isMonerisTesting,
        {
          store_id: groupedInvoiceItem.MonerisAPICredential.MonerisStoreID,
          api_token: groupedInvoiceItem.MonerisAPICredential.APIToken,
          checkout_id:
            groupedInvoiceItem.MonerisAPICredential.MonerisCardChechoutID,
        },
        formData,
        paynow,
        currencyCode,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        monerisSaveCardOptions
      );

      if (index === groupedInvoices.length - 1) {
        setLoading(false);
        setIsCardSaveOpen(true);
      }
    }
  } catch (err) {
    setLoading(false);
    setIsCardSaveFailedOpen(true);
    console.warn(err);
  }
};

/**
 * Retrieves the initial payment ticket from the Moneris system and starts the payment process.
 *
 * - Calculates the total for the selected invoices and formats it to two decimal places.
 * - Sends a request to Moneris to obtain an initial payment ticket.
 * - If successful, calls `paymentHandleInitialTicket` to process the payment using the received ticket.
 * - Logs and handles errors, setting the appropriate payment failure reason if the ticket retrieval fails.
 *
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} accName - The account name for the customer.
 * @param {Object} credentials - The credentials needed for authentication, such as store ID and API token.
 * @param {Array<Object>} invoices - The list of invoices to be processed for payment.
 * @param {Object} formData - Form data containing payment details such as cardholder name, card number, expiry date, and CVV.
 * @param {Object} paynow - Payment options related to the current transaction, such as email address.
 * @param {string} currencyCode - The currency code for the transaction (e.g., "USD").
 * @param {Object} monerisPaymentOptions - Additional options for processing the Moneris payment.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Array<number>} selectedRows - List of selected rows representing the invoices being paid.
 * @param {Array<Object>} invoiceItems - List of all invoice items available for processing.
 * @param {Object} invoicesListForPut - A reference object holding invoices that are ready for the PUT request.
 * @param {Object} data - Additional data used for invoice calculations.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the initial payment ticket is successfully retrieved and the payment process is started.
 * @throws {Error} Logs and throws an error if the initial payment ticket retrieval fails.
 */
const getInitialTicketForPayment = async (
  isMonerisTesting,
  accName,
  credentials,
  invoices,
  formData,
  paynow,
  currencyCode,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  data,
  setPaymentFailureReason
) => {
  const total = calculateTotalForInvoices(invoices, data, selectedRows);

  // Convert total to a formatted string with two decimal places
  const formattedTotal = (total / 100).toFixed(2);

  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/moneris/manage-tickets`,
      {
        ...paymentInitialTicketPayload,
        ...credentials,
        txn_total: formattedTotal,
        cust_id: accName.replace(/[@#$%^&*()!~']/g, " "),
        corp_id: corpid,
        guid: guid,
        action_type: "Payment Handle InitialTicket",
        IsTesting: isMonerisTesting,
      }
    );

    if (data?.response?.success !== "true") {
      setPaymentFailureReason(JSON.stringify(data?.response?.error));
      throw new Error(`Failed to get the initial payment ticket`);
    }

    if (data?.response?.ticket) {
      await paymentHandleInitialTicket(
        isMonerisTesting,
        accName,
        credentials,
        data?.response?.ticket,
        invoices,
        formData,
        paynow,
        currencyCode,
        monerisPaymentOptions,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        selectedRows,
        invoiceItems,
        invoicesListForPut,
        setPaymentFailureReason
      );
    }
  } catch (err) {
    console.warn(err);
    throw new Error("Failed to get initial payment ticket");
  }
};

/**
 * Handles the initial step of processing a payment using the Moneris system by sending the initial ticket.
 *
 * - Constructs the payload with cardholder details, payment information, and additional Moneris-specific options.
 * - Sends a request to process the initial payment transaction and retrieve a final ticket.
 * - If successful, calls `paymentHandleFinalTicket` to finalize the payment.
 * - Logs and handles errors, setting the appropriate payment failure reason if the transaction fails.
 *
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} accName - The account name for the customer.
 * @param {Object} credentials - The credentials needed for authentication, such as store ID and API token.
 * @param {string} ticket - The initial ticket received from the Moneris system for processing the payment.
 * @param {Array<Object>} invoices - The list of invoices to be processed for payment.
 * @param {Object} formData - Form data containing payment details such as cardholder name, card number, expiry date, and CVV.
 * @param {Object} paynow - Payment options related to the current transaction, such as email address.
 * @param {string} currencyCode - The currency code for the transaction (e.g., "USD").
 * @param {Object} monerisPaymentOptions - Additional options for processing the Moneris payment.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Array<number>} selectedRows - List of selected rows representing the invoices being paid.
 * @param {Array<Object>} invoiceItems - List of all invoice items available for processing.
 * @param {Object} invoicesListForPut - A reference object holding invoices that are ready for the PUT request.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the initial payment processing is complete and the final ticket is processed.
 * @throws {Error} Logs and throws an error if the initial payment ticket handling fails.
 */
const paymentHandleInitialTicket = async (
  isMonerisTesting,
  accName,
  credentials,
  ticket,
  invoices,
  formData,
  paynow,
  currencyCode,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  setPaymentFailureReason
) => {
  try {
    let payload = {
      ticket: ticket,
      action: "process_transaction",
      cardholder: formData.cardHolderName,
      pan: formData.cardNumber.replace(/-/g, ""),
      expiry_date: formData.expiry.replace(/\//g, ""),
      cvv: formData.cvv,
      email: paynow?.Email,
      currency_code: currencyCode,
      card_data_key: "new",
      pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
      guid: guid,
      corp_id: corpid,
      action_type: "Payment Process transaction",
      IsTesting: isMonerisTesting,
    };

    if (monerisPaymentOptions?.FirstNameSet === "true") {
      payload.first_name = formData.first_name;
    }

    if (monerisPaymentOptions?.LastNameSet === "true") {
      payload.last_name = formData.last_name;
    }

    if (monerisPaymentOptions?.PhoneNumSet === "true") {
      payload.phone = formData.phone;
    }

    const { data, status } = await axios.post(
      `${API_BASE_URL}/moneris/process-transactions`,
      payload
    );

    if (data?.response?.success !== "true") {
      setPaymentFailureReason(JSON.stringify(data?.response?.error));
      throw new Error(`Failed to handle the initial payment ticket`);
    }

    if (
      data &&
      data.response &&
      data.response.success === "true" &&
      data.response.ticket
    ) {
      await paymentHandleFinalTicket(
        isMonerisTesting,
        accName,
        credentials,
        data.response.ticket,
        invoices,
        formData,
        monerisPaymentOptions,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        selectedRows,
        invoiceItems,
        invoicesListForPut,
        setPaymentFailureReason
      );
    } else {
      console.error("Response data is invalid or incomplete:", data);
      throw new Error("Failed to initialise payment");
    }
  } catch (error) {
    setLoading(false);
    console.error("Error:", error);
    throw new Error("Failed to initialize payment");
  }
};

/**
 * Handles the final step of processing a payment using the Moneris system by handling the final ticket.
 *
 * - Sends the final ticket to the Moneris API and verifies the success of the transaction.
 * - If the transaction is successful, it calls `accumulateInvoicesForPut` to process and update the invoice details.
 * - Logs and handles errors, setting the appropriate payment failure reason if the transaction fails.
 *
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} accName - The account name for the customer.
 * @param {Object} credentials - The credentials needed for authentication, such as store ID and API token.
 * @param {string} ticket - The final ticket received from the Moneris system for processing the payment.
 * @param {Array<Object>} invoices - The list of invoices to be processed for payment.
 * @param {Object} formData - Form data containing payment details, such as cardholder name, billing address, and card details.
 * @param {Object} monerisPaymentOptions - Additional options for processing the Moneris payment.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Array<number>} selectedRows - List of selected rows representing the invoices being paid.
 * @param {Array<Object>} invoiceItems - List of all invoice items available for processing.
 * @param {Object} invoicesListForPut - A reference object holding invoices that are ready for the PUT request.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the payment is finalized and processed.
 * @throws {Error} Logs and throws an error if the final payment ticket handling fails.
 */
const paymentHandleFinalTicket = async (
  isMonerisTesting,
  accName,
  credentials,
  ticket,
  invoices,
  formData,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  setPaymentFailureReason
) => {
  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/moneris/manage-tickets`,
      {
        ...paymentReceiptPayload,
        ...credentials,
        ticket,
        corp_id: corpid,
        guid: guid,
        action_type: "Payment Handle Final Ticket",
        IsTesting: isMonerisTesting,
      }
    );

    if (data?.response?.success !== "true") {
      setPaymentFailureReason(JSON.stringify(data?.response?.error));
      throw new Error(`Failed to handle the final payment ticket`);
    }

    const responseCode = data?.response?.receipt?.cc?.response_code;

    if (
      !responseCode ||
      parseInt(responseCode) < 0 ||
      parseInt(responseCode) > 29
    ) {
      setPaymentFailureReason(
        `${data?.response?.receipt?.cc?.response_code}: ${data?.response?.receipt?.cc?.message}`
      );
      throw new Error(`Failed to handle the final payment ticket`);
    }

    if (data?.response) {
      await accumulateInvoicesForPut(
        isMonerisTesting,
        accName,
        data.response,
        invoices,
        formData,
        monerisPaymentOptions,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        selectedRows,
        invoiceItems,
        invoicesListForPut,
        setPaymentFailureReason
      );
    } else {
      throw new Error("Failed to finalize payment");
    }
  } catch (err) {
    console.warn(err);
    setLoading(false);
    throw new Error("Failed to finalize payment");
  }
};

/**
 * Accumulates and prepares invoice items for a PUT request to process payments with Moneris.
 *
 * - Filters and processes selected invoice items by adding transaction details (e.g., transaction date, ID, payment status).
 * - Combines processed invoice items with any additional unpaid invoices from `invoiceItems` that share the same Moneris checkout ID.
 * - Calls `paymentPutRequest` to update the backend with the processed invoice details.
 *
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} accName - The account name for the customer.
 * @param {Object} dataSuccessResponse - The successful response from the Moneris transaction, containing transaction details.
 * @param {Array<Object>} invoices - The list of invoices to be processed for the payment.
 * @param {Object} formData - Form data containing payment and billing details.
 * @param {Object} monerisPaymentOptions - Additional options for processing the Moneris payment.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Array<number>} selectedRows - List of selected rows representing the invoices being paid.
 * @param {Array<Object>} invoiceItems - List of all invoice items available for processing.
 * @param {Object} invoicesListForPut - A reference object holding invoices that are ready for the PUT request.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the accumulated invoices are successfully processed with a PUT request.
 * @throws {Error} Logs and throws an error if the payment PUT request fails.
 */
const accumulateInvoicesForPut = async (
  isMonerisTesting,
  accName,
  dataSuccessResponse,
  invoices,
  formData,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  setPaymentFailureReason
) => {
  let invoiceItemsTmp = [];
  let addedInvoices = [];

  let checkoutId = invoices[0].MonerisAPICredential.MonerisPaymentChechoutID;

  try {
    if (selectedRows.length === 0) {
      invoiceItemsTmp = invoices;
    } else {
      for (let i = 0; i < invoices.length; i++) {
        if (selectedRows.includes(invoices[i].index)) {
          const paymentAmount = parseInt(
            invoices[i].PaymentResponse.PaymentAmount.replace(/[,]/g, "")
          );
          const updatedPaymentResponse = {
            TransactionDate:
              dataSuccessResponse.receipt.cc.transaction_date_time,
            TransactionID: dataSuccessResponse.receipt.cc.transaction_no,
            ReferenceNumber: dataSuccessResponse.receipt.cc.reference_no,
            PaymentStatus: 1,
            PaymentAmount:
              paymentAmount !== 0 ? paymentAmount : invoices[i]?.AmountDue,
            ResponseCode: dataSuccessResponse.receipt.cc.response_code,
            ResponseMessage: "successfully",
          };
          invoices[i].PaymentResponse = updatedPaymentResponse;
          invoiceItemsTmp.push(invoices[i]);
        }
      }

      addedInvoices = invoiceItemsTmp.map((item) => item.InvoiceNum);

      for (let i = 0; i < invoiceItems.length; i++) {
        if (
          !addedInvoices.includes(invoiceItems[i].InvoiceNum) &&
          invoiceItems[i].MonerisAPICredential.MonerisPaymentChechoutID ===
            checkoutId
        ) {
          invoiceItemsTmp.push(invoiceItems[i]);
        }
      }
    }

    await paymentPutRequest(
      isMonerisTesting,
      accName,
      [...invoicesListForPut.current, ...invoiceItemsTmp],
      {
        CcType: dataSuccessResponse.receipt.cc.card_type,
        Last4Num: dataSuccessResponse.receipt.cc.first6last4?.slice(-4),
      },
      formData,
      monerisPaymentOptions,
      setLoading,
      endpoint,
      guid,
      corpid,
      token,
      sendrespons,
      setPaymentFailureReason
    );
  } catch (err) {
    console.warn(err);
    setLoading(false);
    throw new Error("Failed payment PUT request");
  }
};

/**
 * Sends a PUT request to SDS for the payment.
 *
 * - Constructs the payload with invoice items, Moneris card information, and optional billing address details.
 * - Sends the updated payload to the backend via a PUT request to record the payment transaction.
 * - Logs success or failure of the operation and sets the appropriate payment failure reason if needed.
 *
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} accName - The account name for the customer.
 * @param {Array<Object>} invoices - List of invoice items being processed for the payment.
 * @param {Object} monerisCardInfo - Moneris credit card details object containing card info and token.
 * @param {Object} formData - Form data containing billing details.
 * @param {Object} monerisPaymentOptions - Additional options for processing the Moneris payment.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the payment PUT request is successfully processed.
 * @throws {Error} Logs the error if the payment PUT request fails and updates the failure reason.
 */
const paymentPutRequest = async (
  isMonerisTesting,
  accName,
  invoices,
  monerisCardInfo,
  formData,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  setPaymentFailureReason
) => {
  let data = sendrespons;
  try {
    const itemType = sendrespons.ItemType;

    data = {
      ...data,
      TheMonerisCC: monerisCardInfo,
      IsCcSaved: false,
      corp_id: corpid,
      guid: guid,
      IsTesting: isMonerisTesting,
    };

    if (itemType === 0) {
      data = {
        ...data,
        InvoiceItems: invoices,
      };
    } else if (itemType === 1) {
      data = {
        ...data,
        CiaByWeekItems: invoices,
      };
    } else if (itemType === 2) {
      data = {
        ...data,
        CiaByMonthItems: invoices,
      };
    } else if (itemType === 3) {
      data = {
        ...data,
        CiaPrepayItems: invoices,
      };
    }

    if (monerisPaymentOptions?.BillingAddressSet === "true") {
      data.PayerAccount = {
        ...data.PayerAccount,
        Address: {
          Line1: formData.addressLine1,
          Line2: formData.addressLine2,
          City: formData.city,
          Country: formData.country,
          PostalCode: formData.postalCode,
          Province: formData.province,
        },
      };
    }

    const response = await axios.put(`${endpoint}/Put?id=${guid}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Origin_Custom: endpoint,
      },
    });

    storeLog(
      "info",
      `Payment PUT request: "${JSON.stringify(data)}"`,
      response.status,
      guid,
      corpid,
      "PUT Call - Payment",
      `PUT Call - Payment in moneris for ${accName}`,
      "Success"
    );
  } catch (error) {
    setPaymentFailureReason("PUT call failed");
    storeLog(
      "error",
      `Payment PUT request: "${JSON.stringify(data)}"`,
      500,
      guid,
      corpid,
      "PUT Call - Payment",
      `PUT Call - Payment in moneris for ${accName}`,
      "Failure"
    );
    setLoading(false);
    console.error("Error fetching data:", error);
    throw new Error("Failed payment PUT request");
  }
};

/**
 * Handles the payment process for multiple grouped invoices for Moneris.
 *
 * - Validates the form data before proceeding with payment.
 * - Iterates through each grouped invoice item and calls `getInitialTicketForPayment` for processing valid invoices.
 * - Manages loading state during the payment process and shows success or failure modals based on the result.
 *
 * @param {Event} e - The event object from the form submission.
 * @param {boolean} isMonerisTesting - Parmater that denotes if we're in testing or production
 * @param {string} accName - The account name for the customer.
 * @param {Object} formData - Form data containing payment details such as cardholder name, card number, expiry date, and CVV.
 * @param {Object} paynow - Payment options related to the current transaction.
 * @param {string} currencyCode - The currency code for the transaction (e.g., "USD").
 * @param {Object} monerisPaymentOptions - Additional options for processing the Moneris payment.
 * @param {Function} setLoading - Function to control the loading state.
 * @param {string} endpoint - The API endpoint URL.
 * @param {string} guid - GUID for the transaction.
 * @param {string} corpid - CorpID associated with the transaction.
 * @param {string} token - Authorization token for the request.
 * @param {Array<Object>} groupedInvoices - List of grouped invoice items containing Moneris API credentials.
 * @param {Function} setFailedIsOpen - Function to control the display of the failure modal.
 * @param {Function} setISOpen - Function to control the display of the success modal.
 * @param {Array<number>} selectedRows - List of selected rows representing items being paid.
 * @param {Object} sendrespons - The response object to update and send with the request.
 * @param {Array<Object>} invoiceItems - List of invoice items being processed.
 * @param {Array<Object>} invoicesListForPut - List of invoices to update after the payment is completed.
 * @param {Object} data - Additional data for the transaction.
 * @param {Function} setErrors - Function to set validation errors.
 * @param {Function} setPaymentFailureReason - Function to set the reason for payment failure.
 *
 * @returns {Promise<void>} A promise that resolves when the payment process is complete.
 * @throws {Error} Logs the error if any part of the payment process fails.
 */
export const handlePayment = async (
  e,
  isMonerisTesting,
  accName,
  formData,
  paynow,
  currencyCode,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  groupedInvoices,
  setFailedIsOpen,
  setISOpen,
  selectedRows,
  sendrespons,
  invoiceItems,
  invoicesListForPut,
  data,
  setErrors,
  setPaymentFailureReason
) => {
  e.preventDefault();

  if (!validateForm(formData, setErrors)) {
    return;
  }

  setLoading(true);

  let validGroupsCount = 0;
  let modifiedGroup = [];

  groupedInvoices.forEach(async (groupedInvoiceItem, index) => {
    const validElementsCount = groupedInvoiceItem.invoices.filter((item) =>
      selectedRows.includes(item.index)
    ).length;

    if (validElementsCount !== 0) {
      validGroupsCount += 1;
      modifiedGroup.push(groupedInvoiceItem);
    }
  });

  modifiedGroup.forEach(async (groupedInvoiceItem, index) => {
    const validElementsCount = groupedInvoiceItem.invoices.filter((item) =>
      selectedRows.includes(item.index)
    ).length;
    if (validElementsCount !== 0) {
      try {
        await getInitialTicketForPayment(
          isMonerisTesting,
          accName,
          {
            store_id: groupedInvoiceItem.MonerisAPICredential.MonerisStoreID,
            api_token: groupedInvoiceItem.MonerisAPICredential.APIToken,
            checkout_id:
              groupedInvoiceItem.MonerisAPICredential.MonerisPaymentChechoutID,
          },
          groupedInvoiceItem.invoices,
          formData,
          paynow,
          currencyCode,
          monerisPaymentOptions,
          setLoading,
          endpoint,
          guid,
          corpid,
          token,
          sendrespons,
          selectedRows,
          invoiceItems,
          invoicesListForPut,
          data,
          setPaymentFailureReason
        );

        if (index === validGroupsCount - 1) {
          setISOpen(true);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setFailedIsOpen(true);
        console.warn(err);
      }
    }
  });
};
